import CactusPilotCard from "./components/CactusPilotCard";
import { MDBCard } from "mdb-react-ui-kit";
import RefLink from "../../RefLink/RefLink";
import { SERVICE_DISABLED_TEXT, SERVICE_ERROR_TEXT } from "../../../constants";
import HeaderContent from "./components/HeaderContent";
import Statistic from "./components/Statistic";
import ReferenceList from "./components/ReferenceList";
import { useAuth } from "../../Auth";

const similarityColors = new Map([
  [SERVICE_DISABLED_TEXT.toLowerCase(), "bg-secondary"],
  [SERVICE_ERROR_TEXT.toLowerCase(), "bg-secondary"],
  ["green", "bg-success"],
  ["orange", "bg-warning"],
  ["red", "bg-danger"],
]);

const getCardColorClass = (conditions) => {
  return conditions.some((cond) => cond?.length) ? "bg-danger" : "bg-success";
};

const getSimilarityColorClass = (similarityData) => {
  return similarityColors.get(similarityData?.toLowerCase()) || "bg-secondary";
};

const renderRetractedItem = (item, index) => (
  <RefLink
    item={item}
    pubpeer={true}
    index={index}
    key={index}
    textColor="white"
  />
);

const renderMentionItem = ({ doi, comments }, index) => (
  <RefLink
    item={doi}
    pubpeer={true}
    index={index}
    key={index}
    comments={comments}
    textColor="white"
  />
);

const ExternalToolsTab = ({
  cactusData,
  pubPeerMentionsData,
  papermillSimiarityData,
}) => {
  const { componentFlags } = useAuth();

  const { refIdentifiers, referencesRetractions } = pubPeerMentionsData || {};
  const { extracted, parsed } = refIdentifiers || {};
  const { details: mentions, retracted: retractedRefs } =
    referencesRetractions || {};

  return (
    <>
      {/* PubPeer mentions */}
      <MDBCard
        className={`mt-4 bg-opacity-25 ${getCardColorClass([
          mentions,
          retractedRefs,
        ])}`}
      >
        <HeaderContent cardTitle="PubPeer mentions" />
        <div className="card-body p-3 bg-white bg-opacity-25">
          <ReferenceList
            title="References retracted"
            references={retractedRefs}
            isDataAvailable={referencesRetractions}
            renderFunction={renderRetractedItem}
          />
          <ReferenceList
            title="Mention"
            references={mentions}
            isDataAvailable={referencesRetractions}
            renderFunction={renderMentionItem}
            isLastElement={true}
          />
        </div>
        <div className="card-footer border-0 px-3 py-4">
          <Statistic
            label="Total number of references listed in manuscript"
            value={parsed}
          />
          <Statistic
            label="From which DOIs were listed or could be retrieved"
            value={extracted}
          />
        </div>
      </MDBCard>

      {/* Papermill similarity */}
      <MDBCard
        className={`mt-4 bg-opacity-25 ${getSimilarityColorClass(
          papermillSimiarityData
        )}`}
      >
        <div className="card-header p-3 pt-4 pb-3 border-0">
          <div className="d-flex justify-content-between">
            <h5 className="card-title fw-normal">Papermill similarity</h5>
          </div>
        </div>
        <div className="card-body bg-white bg-opacity-25 p-3">
          <span>{papermillSimiarityData}</span>
        </div>
      </MDBCard>

      {/* Cactus */}
      {componentFlags?.cactusTool && (
        <CactusPilotCard cactusTool={cactusData} />
      )}
    </>
  );
};

export default ExternalToolsTab;
