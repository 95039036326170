import { MDBCard, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Fragment } from "react";
import TorturedPhrases from "../../TorturedPhrases/TorturedPhrases";
import ScoreCard from "../../ScoreCard/ScoreCard";
import HeaderContent from "./components/HeaderContent";
import Statistic from "./components/Statistic";
import ReferenceList from "./components/ReferenceList";

const getCardColorClass = (conditions) => {
  return conditions.some((cond) => cond?.length) ? "bg-danger" : "bg-success";
};

const renderItem = (item, index) => (
  <Fragment key={item.DOI}>
    {index + 1}. {item.DOI} '{item.title}'<br />
  </Fragment>
);

const AnalysisToolsTab = ({
  doiAnalysisData,
  retractionWatchData,
  torturedPhrasesData,
  unnaturalTextsData,
}) => {
  const { extracted, invalid, parsed, unidentified } = doiAnalysisData || {};

  return (
    <>
      {/* DOI analysis */}
      <MDBCard className={`mt-4 bg-opacity-25 ${getCardColorClass([invalid])}`}>
        <HeaderContent cardTitle="DOI analysis" />
        <div className="card-body bg-white bg-opacity-25 p-3">
          <ReferenceList
            title="Listed DOIs that do not exist"
            references={invalid}
            isDataAvailable={true}
            renderFunction={renderItem}
          />
          <ReferenceList
            title="Non retrieved reference titles"
            references={unidentified}
            isDataAvailable={unidentified}
            renderFunction={renderItem}
            isLastElement={true}
          />
        </div>
        <div className="card-footer border-0 px-3 py-4">
          <Statistic
            label="Total number of references listed in manuscript"
            value={parsed}
          />
          <Statistic
            label="From which DOIs were listed or could be retrieved"
            value={extracted}
          />
          <Statistic
            label="Number of listed DOIs that do not exist"
            value={invalid?.length}
          />
          <Statistic
            label="Non retrieved references"
            value={unidentified?.length}
          />
        </div>
      </MDBCard>

      {/* Retraction Watch */}
      <MDBCard
        className={`mt-4 bg-opacity-25 ${getCardColorClass([
          retractionWatchData,
        ])}`}
      >
        <HeaderContent cardTitle="Retraction Watch" />
        <div className="card-body p-3 bg-white bg-opacity-25">
          <ReferenceList
            title="Mentioned references"
            references={retractionWatchData}
            isDataAvailable={retractionWatchData}
            renderFunction={renderItem}
            isLastElement={true}
          />
        </div>
        <div className="card-footer border-0 px-3 py-4">
          <Statistic
            label="Total number of references listed in manuscript"
            value={parsed}
          />
          <Statistic
            label="From which DOIs were listed or could be retrieved"
            value={extracted}
          />
        </div>
      </MDBCard>

      {/* Tortured Phrases */}
      <MDBCard
        className={`mt-4 bg-opacity-25 ${
          !torturedPhrasesData?.length ? "bg-success" : "bg-white"
        }`}
      >
        <div
          className={`card-header p-3 pt-4 border-0 bg-opacity-25 ${
            torturedPhrasesData?.length ? "bg-danger" : null
          }`}
        >
          <div className="mb-2 d-flex justify-content-between">
            <h5 className="card-title fw-normal">Tortured phrases</h5>
          </div>
          <span>
            Strange terms often a result of automatic translation or attempts to
            disguise plagiarism. An exact or approximate match (fuzzy) of our
            list of tortured phrases will trigger a score, which increases as
            more are found.
          </span>
          <div className="d-flex mt-2">
            <span className="fw-bold">
              Database: <span className="ms-1">6683 phrases</span>
            </span>
          </div>
        </div>
        <div
          className={`card-body bg-white bg-opacity-25 ${
            torturedPhrasesData?.length ? "mb-4" : ""
          }`}
        >
          {torturedPhrasesData?.length
            ? torturedPhrasesData.map((item, index) => (
                <TorturedPhrases key={index} item={item} index={index} />
              ))
            : torturedPhrasesData === null
            ? "N/A"
            : "None found"}
        </div>
      </MDBCard>

      {/* Unnatural Texts */}
      <MDBCard
        className={`px-3 py-4 mt-4 text-dark bg-opacity-25 ${
          unnaturalTextsData === "N/A" || unnaturalTextsData <= 25
            ? "bg-success"
            : "bg-danger"
        }`}
      >
        <MDBRow>
          <MDBCol md="9">
            <div className="d-flex justify-content-between">
              <h5 className="fw-normal">Unnatural text</h5>
            </div>
            <span className="mb-4">
              We map all text using an algorithm which puts indications on a
              scale. Using a corpus of generated AI articles, we evaluate all
              indications to each other to find evidence for AI generation.
            </span>
          </MDBCol>

          <MDBCol md="3">
            <ScoreCard score={unnaturalTextsData} />
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </>
  );
};

export default AnalysisToolsTab;
