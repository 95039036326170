import React from 'react';
import CustomPopover from './CustomPopover';
import { SERVICE_DISABLED_TEXT } from '../../constants';

const ServiceDisabledPopover = () => (
    <CustomPopover popoverHeader="Tool not available" popoverBody={SERVICE_DISABLED_TEXT}>
        <span className='ps-2'>n/a</span>
    </CustomPopover>
);

export default ServiceDisabledPopover;