import React, { useState } from 'react';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { suspectTypeSelectOptions } from '../../../constants';
import {
    duplicateWatchlistRecord,
    showErrorToast,
    showSuccessToast,
} from '../../../helpers';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBModalFooter,
    MDBRow,
    MDBTooltip,
} from 'mdb-react-ui-kit';
import { useAuth } from '../../../components/Auth';

const DetailedViewModal = ({ onClose, item }) => {
    const isOpen = !!item;
    const [isLoading, setIsLoading] = useState(false);
    const isBadActors = item?.type === suspectTypeSelectOptions[4].label;

    // const { isPublisherAdmin } = useAuth();
    const { isPermissionGranted } = useAuth();

    const handleCloseModal = () => onClose();

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await duplicateWatchlistRecord(item.id);
            showSuccessToast();
            handleCloseModal();
        } catch (error) {
            showErrorToast();
        } finally {
            setIsLoading(false);
        }
    };

    const renderField = (label, value) => {
        return (
            <p>
                <strong>{label}:</strong> {value ?? ''}
            </p>
        );
    };

    const renderBadActorDetails = () => {
        return (
            <>
                {renderField('Type', item?.type)}
                <MDBCard className="mb-4">
                    <MDBCardHeader>
                        <MDBCardTitle className="my-1">Bad actor</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            {renderField('Name', item?.sourceValue?.name)}
                            {renderField('Institution', item?.sourceValue?.institution)}
                            {renderField('Email', item?.sourceValue?.email)}
                            {renderField('Phone', item?.sourceValue?.phone)}
                            {renderField('ORCID', item?.sourceValue?.ORCID)}
                            {renderField('IP address', item?.sourceValue?.ip)}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
                {renderField('Breach type', item?.breach)}
                {renderField('Provided by', item?.providedby)}
                {renderField('Timestamp', item?.timestamp)}
                {renderField('Comment', item?.comment)}
            </>
        );
    };

    const renderAnyHitDetails = () => {
        return (
            <>
                {renderField('Type', item?.type)}
                {renderField('Value', item?.value)}
                {renderField('Provided by', item?.providedby)}
                {renderField('Timestamp', item?.timestamp)}
                {renderField('Comment', item?.comment)}
            </>
        );
    };

    return (
        <ModalWrapper
            title={'Watchlist'}
            isOpen={isOpen}
            isLoading={isLoading}
            onClose={handleCloseModal}
        >
            <MDBRow>
                {isBadActors ? renderBadActorDetails() : renderAnyHitDetails()}
            </MDBRow>
            {isPermissionGranted('publisherSupervisoryRole') && (
                <MDBModalFooter className="justify-content-center">
                    <MDBTooltip tag='span'
                        title={
                            <ol style={{ textAlign: 'left' }}>
                                Add comment: Will first copy the watchlist entry after which it appears under the admin feature and can be provided with its own comment via export/import.
                                <li>Hit "Add comment".</li>
                                <li>Switch to "admin".</li>
                                <li>"Export" the list.</li>
                                <li>Add the comment in Excel.</li>
                                <li>"Import" the list.</li>
                            </ol>
                        }
                    >
                        <MDBBtn onClick={handleSubmit} color="success">
                            Add comment
                        </MDBBtn>
                    </MDBTooltip>
                </MDBModalFooter>
            )}
        </ModalWrapper >
    );
};

export default DetailedViewModal;
