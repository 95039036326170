import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";

const STATUS_MESSAGE = process.env.REACT_APP_STATUS_MESSAGE;

const SystemStatusIndicator = () => {
  if (!STATUS_MESSAGE) return null;

  return (
    <MDBBtn className="me-1" color="warning">
      {STATUS_MESSAGE}
      <MDBIcon className="ms-2" icon="exclamation-triangle" size="lg" />
    </MDBBtn>
  );
};

export { SystemStatusIndicator };
