import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./Modal.css";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBTypography,
} from "mdb-react-ui-kit";
import { convertToNumber } from "../../helpers/helpers";
import WatchlistTab from "./tabs/WatchlistTab";
import ModalHeader from "./ModalHeader";
import { isEmptyObject } from "../../helpers/helpers";
import {
  SERVICE_DISABLED_TEXT,
  SERVICE_ERROR_TEXT,
  tabs,
} from "../../constants";
import { useAuth } from "../Auth";
import ExternalToolsTab from "./tabs/ExternalToolsTab";
import AnalysisToolsTab from "./tabs/AnalysisToolsTab";
import dayjs from "dayjs";

export const markToHref = (signals) => {
  if (!Array.isArray(signals)) {
    return [];
  }

  return signals?.map((signal) => {
    if (signal?.sentence?.length) {
      const sentence = signal?.sentence?.map((element) => {
        element = element.replace(
          "<mark>",
          `<a href="https://pubpeer.com/search?q=${encodeURIComponent(
            signal.pattern
          )}" target="_blank">`
        );
        element = element.replace(
          "</mark>",
          '<i className="ps-1 fa fa-external-link-alt fa-sm"></i></a>'
        );
        return element;
      });
      return { ...signal, sentence };
    }
    return { ...signal };
  });
};

const Modal = ({
  isOpen,
  setIsOpen,
  tab,
  data,
  onDeleteEvent,
  onLoadReport,
  eventDeleting,
  components,
}) => {
  const {
    documentTitle,
    clearSkiesStatus,
    signals,
    gptDetectorScore,
    feetOfClayRatio,
    refIdentifiers,
    retractionWatch,
    cactusTool,
    SK,
  } = data;

  const title = documentTitle || "";
  const tortured_phrases = signals !== null ? markToHref(signals || []) : null;
  const aiScore =
    gptDetectorScore !== null ? convertToNumber(gptDetectorScore) : "N/A";
  const text = clearSkiesStatus
    ? !isEmptyObject(clearSkiesStatus)
      ? clearSkiesStatus[0].toUpperCase() + clearSkiesStatus.substring(1)
      : SERVICE_ERROR_TEXT
    : SERVICE_DISABLED_TEXT;
  const suspects = data?.suspects ?? null;
  const referencesRetractions = feetOfClayRatio ?? null;
  const timestamp = dayjs(SK?.slice(10)).format("YYYY.MM.DD HH:mm");

  const { isFeatureEnabled } = useAuth();

  const availableTabs = useMemo(() => {
    const baseTabs = [
      {
        id: tabs.externalTools,
        title: "Vendor tools",
        component: (
          <>
            <ExternalToolsTab
              cactusData={cactusTool}
              pubPeerMentionsData={{
                refIdentifiers: refIdentifiers,
                referencesRetractions: referencesRetractions,
              }}
              papermillSimiarityData={text}
            />
          </>
        ),
      },
      {
        id: tabs.analysisTools,
        title: "Analysis tools",
        component: (
          <>
            <AnalysisToolsTab
              doiAnalysisData={refIdentifiers}
              retractionWatchData={retractionWatch}
              torturedPhrasesData={tortured_phrases}
              unnaturalTextsData={aiScore}
            />
          </>
        ),
      },
      {
        id: tabs.watchlist,
        title: "Watchlist",
        component: <WatchlistTab suspects={suspects} components={components} />,
      },
    ];

    return baseTabs;
  }, [
    isOpen,
    refIdentifiers,
    retractionWatch,
    referencesRetractions,
    cactusTool,
    text,
    tortured_phrases,
    aiScore,
    suspects,
    components,
    isFeatureEnabled,
  ]);

  const [currentTab, setCurrentTab] = useState(tab);

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  const handleTabClick = useCallback((tabId) => {
    setCurrentTab(tabId);
  }, []);

  return (
    <MDBModal show={isOpen} setShow={setIsOpen}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsOpen((prev) => !prev)}
            />
          </MDBModalHeader>
          <MDBModalBody className="m-3">
            <div className="my-2 mx-1">
              {timestamp && <small>{timestamp} | </small>}
              {data?.userEmail && (
                <small className="ms-1">{data?.userEmail} | </small>
              )}
              {data?.manuscriptId && (
                <small className="ms-1">{data?.manuscriptId} | </small>
              )}
              {data?.documentName && (
                <small className="ms-1">{data?.documentName}</small>
              )}
            </div>
            <ModalHeader
              title={title}
              onLoadReport={onLoadReport}
              eventDeleting={eventDeleting}
              onDeleteEvent={onDeleteEvent}
            />
            <MDBTabs className="mb-3">
              {availableTabs.map((tabConfig) => (
                <MDBTabsItem key={tabConfig.id}>
                  <MDBTabsLink
                    onClick={() => handleTabClick(tabConfig.id)}
                    active={currentTab === tabConfig.id}
                  >
                    {tabConfig.title}
                  </MDBTabsLink>
                </MDBTabsItem>
              ))}
            </MDBTabs>
            <MDBTabsContent>
              {availableTabs.map((tabConfig) => (
                <MDBTabsPane
                  show={currentTab === tabConfig.id}
                  key={tabConfig.id}
                >
                  {tabConfig.component}
                </MDBTabsPane>
              ))}
            </MDBTabsContent>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default Modal;
