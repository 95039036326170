import React from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import { withFormik, useField } from 'formik';
import Loader from './Loader';
import { showSuccessToast, showErrorToast } from '../helpers/helpers';
import { updatePassword } from '../helpers/events';
import { regExpPassword, errorMessage } from "../constants";
import './LoginForm.css';

const TextInputMeta = ({ type, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className="mb-3">
            <label className="grey-text">
                {props.label}
            </label>
            <input
                type={type !== "text" ? type : "text"} className="form-control"
                {...field} {...props}
            />
            {
                meta.touched && meta.error
                    ? (<div className="text-danger">{meta.error}</div>)
                    : null
            }
        </div>
    );
};

const mapPropsToValues = () => ({
    password: '',
    passwordConfirm: '',
});

const validate = (value, props) => {
    const errors = {};

    if (!value.password) {
        errors.password = "'Password' is required";
    }
    if (value.password && !value.password.match(regExpPassword)) {
        errors.password = "Minimum eight characters, at least one uppercase and one lowercase letter, one number and one special character";
    }
    if (!value.passwordConfirm) {
        errors.passwordConfirm = "'Password confirmation' is required";
    }
    if (value.passwordConfirm && value.password !== value.passwordConfirm) {
        errors.passwordConfirm = "Passwords do not match";
    }

    return errors;
}

const handleSubmit = (values, { props, ...actions }) => {
    if (values.password === props.authResponse.currentPassword) {
        return showErrorToast('The password must not match any previously used password')
    }
    const { token, username, userId } = props.authResponse;
    props.login({ token, username, components: {} });

    updatePassword(userId, { password: values.password, status: 'ENABLED', passwordSetupType: 3 })
        .then(() => {
            showSuccessToast('Password successfully updated. Please log in using the new credentials.');
            props.setTab('login');
        })
        .catch(error => {
            console.error(error);
            showErrorToast(errorMessage);
        })
        .finally(() => {
            actions.setSubmitting(false);
            props.logout();
        })
};

const PasswordChangeForm = ({ isSubmitting, setTab, status, setStatus, authResponse, handleSubmit, ...props }) => {
    return (
        <div className='main-section'>
            <div className='form_wrapper d-flex justify-content-center'>
                {isSubmitting
                    ? <Loader />
                    : <form className='col-10 col-md-4' onSubmit={handleSubmit}>
                        <h4 className='text-center mb-3 font-weight-bolder'>Update the password</h4>
                        <TextInputMeta name='password' label='Password' type='password' />
                        <TextInputMeta name='passwordConfirm' label='Password confirm' type='password' />
                        <div className='text-center mt-4'>
                            <MDBBtn color='primary' type='submit'>Update</MDBBtn>
                        </div>
                    </form>
                }
            </div>
        </div >
    );
};

export default withFormik({ handleSubmit, mapPropsToValues, validate })(PasswordChangeForm);
