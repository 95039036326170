import React, { useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBFile,
  MDBBtn,
  MDBTypography,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner/Spinner";
import {
  importFileConfirm,
  importFilePresign,
  importFilePreview,
} from "../../helpers/watchlist";
import SuspectsTypeSelect from "./components/SuspectsTypeSelect";

const WatchListImportPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialSuspectsType = location.state?.suspectsType;
  const [suspectsType, setSuspectsType] = useState(initialSuspectsType ?? 1);

  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [generatedFileName, setGeneratedFileName] = useState(null);
  const [filesComparison, setFilesComparison] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileComparison = async () => {
    try {
      if (!file) {
        return;
      }
      setIsLoading(true);
      const filePresignResponse = await importFilePresign(file);
      const { fileName, putObjectUrl } = filePresignResponse.data || {};
      if (!fileName && !putObjectUrl) throw new Error();
      setGeneratedFileName(fileName);

      await axios.put(putObjectUrl, file, {
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });

      const filePreviewResponse = await importFilePreview(
        fileName,
        suspectsType
      );
      setFilesComparison(filePreviewResponse.data);
    } catch (error) {
      console.error('Error uploading file', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileImportConfirm = async () => {
    try {
      setIsLoading(true);
      await importFileConfirm(generatedFileName, suspectsType);
      toast('File imported successfully', {
        type: 'success',
      });
      navigate(-1);
    } catch (error) {
      console.error('Error confirming file import', error);
      toast('Import failed', {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuspectTypeChange = (event) => setSuspectsType(event.value);

  const handleResetSelection = () => {
    fileInputRef.current.value = null;
    setFilesComparison(null);
    setFile(null);
  };

  return (
    <MDBContainer>
      <div className="my-5">
        <MDBRow className="justify-content-center">
          <MDBCol md={6}>
            <MDBTypography className="form-label">
              Select suspect type to import
            </MDBTypography>
            <div className="mb-2">
              <SuspectsTypeSelect
                isDisabled={filesComparison || isLoading}
                suspectType={suspectsType}
                onSuspectTypeChange={handleSuspectTypeChange}
              />
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center mb-4">
          <MDBCol md={6}>
            <MDBFile
              inputRef={fileInputRef}
              disabled={filesComparison || isLoading}
              label="Select Excel file to review difference and import"
              size="lg"
              id="formFileLg"
              onChange={handleFileChange}
            />
          </MDBCol>
        </MDBRow>
        <div className="d-flex justify-content-center mt-3">
          {!filesComparison && (
            <MDBBtn
              color="primary"
              disabled={!file || isLoading || filesComparison}
              onClick={handleFileComparison}
            >
              Review comparison
            </MDBBtn>
          )}
          {filesComparison && (
            <MDBBtn color="danger" onClick={handleResetSelection}>
              Reset selection
            </MDBBtn>
          )}
        </div>
        {filesComparison && (
          <div>
            <div className="d-flex justify-content-center">
              <div className="col-md-6 col-sm-12 m-3">
                <MDBCard className="w-100">
                  <MDBCardHeader>
                    Previous and import data difference
                  </MDBCardHeader>
                  <MDBCardBody>
                    <MDBTypography note noteColor="success">
                      <strong>Added lines:</strong> {filesComparison.added}
                    </MDBTypography>
                    <MDBTypography note noteColor="info">
                      <strong>Untouched lines:</strong>{' '}
                      {filesComparison.untouched}
                    </MDBTypography>
                    <MDBTypography note noteColor="warning">
                      <strong>Updated lines:</strong> {filesComparison.updated}
                    </MDBTypography>
                    <MDBTypography note noteColor="danger">
                      <strong>Removed lines:</strong> {filesComparison.removed}
                    </MDBTypography>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </div>
            <MDBTypography>
              Please note - This sheet and its contents are confidential and should not be circulated to any person outside of STM or the Watchlist who does not have a specific need to know.
            </MDBTypography>
            <MDBTypography>
              However, we cannot guarantee that this sheet is legally privileged, meaning that it may be disclosed in court proceedings. Please complete this sheet under the assumption that it may be seen by all parties involved in this matter at a later date.
            </MDBTypography>
            <MDBTypography>
              As such, we request that you keep the information in this sheet brief and factual. Please do not include any judgement or opinions about the matter, including about any organisations or individuals involved (the Editor, Authors, Complainants etc). Please limit any personal data, for example names or contact details, of any individuals involved in the sheet itself, and only include personal data that is absolutely necessary to the matter.
            </MDBTypography>
            <div className="d-flex justify-content-center mt-3">
              <MDBBtn
                color="primary"
                disabled={!file || isLoading}
                onClick={handleFileImportConfirm}
              >
                Import
              </MDBBtn>
            </div>
          </div>
        )}
        {file && isLoading && <Spinner />}
      </div>
    </MDBContainer>
  );
};

export default WatchListImportPage;
