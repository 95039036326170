import React from "react";
import { SystemStatusIndicator } from "./SystemStatusIndicator";
import { useAuth } from "./Auth";

const Logo = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="d-flex justify-content-between bg-primary text-white p-3 align-items-center">
      <h3 className="mb-0 fw-bold">STM Integrity Hub</h3>
      {isAuthenticated && <SystemStatusIndicator />}
    </div>
  );
};

export default Logo;
