import React from 'react';
import { useDropzone } from 'react-dropzone';
import { MDBIcon } from 'mdb-react-ui-kit';
import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-width: 2px;
  border-radius: 4px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border .12s ease-in-out;
  color: #757575;
`;


const UploadDropzone = props => {
  const onDrop = files => {
    props.onFileSubmit(files)
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: {
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/pdf': [],
      'application/x-zip-compressed': []
    },
  });

  return (
    <div>
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <div className='d-flex align-items-center'>
          <MDBIcon far icon="file" size='2x' className='link-info me-3' />
          <p className='m-auto'>Drag & drop files here, or click to select files</p>
        </div>
      </Container>
    </div>
  );
}

export default UploadDropzone;