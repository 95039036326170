import React, { useCallback } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import './SearchTextField.css';

const SearchTextField = ({ setSearch, search }) => {

  const handleInputChange = useCallback((e) => {
    setSearch(e.target.value ? e.target.value : '');
  }, [setSearch]);

  const handleKeyDown = useCallback((e) => {
    if ((e.code === 'Enter' || e.code === 'NumpadEnter') && e.target.value.length) {
      e.preventDefault();
      setSearch(e.target.value);
    }
  }, [setSearch]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <MDBInput
        type="search"
        className='custom-input'
        style={{
          backgroundColor: 'white',
          borderColor: '#ddd',
        }}
        value={search}
        placeholder='Search'
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
    </form>
  )
}

export default SearchTextField;