import React from "react";
import { MDBCard } from "mdb-react-ui-kit";
import { isObject } from "../../../helpers/helpers";

const renderSuspectEntry = (item, index) => {
  if (isObject(item)) {
    return (
      <React.Fragment key={item.value}>
        <p className="mb-1">
          {index + 1}. {item.value}
        </p>
        {item.providedby && (
          <p className="small mb-1">
            Provided by{" "}
            <a href={`mailto:${item.providedby}`}>{item.providedby}</a>
          </p>
        )}
      </React.Fragment>
    );
  }

  return (
    <p key={item} className="mb-1">
      {index + 1}. {item}
    </p>
  );
};

const renderSuspectEntries = (values) => {
  if (!Array.isArray(values)) {
    return "N/A";
  }

  if (values.length === 0) {
    return "None found";
  }

  return values.map(renderSuspectEntry);
};

const getCardColorClass = (suspects) => {
  return Object.values(suspects || {}).some((value) => value?.length)
    ? "bg-danger"
    : "bg-success";
};

const renderMetadataEntry = (key, { label, content }, isActive) => {
  return (
    isActive && (
      <div className="mb-3" key={key}>
        <p className="fw-bold mb-1">{label}</p>
        {renderSuspectEntries(content)}
      </div>
    )
  );
};

const WatchlistTab = ({ suspects, components }) => {
  const {
    affiliations,
    identities,
    emails,
    tempmails,
    actors,
    articles,
    manuscripts,
    metadata,
    gptOutput,
  } = suspects || {};

  const metadataEntries = {
    watchlistFakeAffiliationNames: {
      label: "Fake affiliation names:",
      content: affiliations,
    },
    watchlistFakeNamesOrEmailAddresses: {
      label: "Fake names or e-mail addresses:",
      content: identities,
    },
    watchlistFakeEmailDomains: {
      label: "Fake e-mail domains:",
      content: emails,
    },
    watchlistDisposableEmailDomains: {
      label: "Disposable e-mail domains:",
      content: tempmails,
    },
    watchlistBadActors: {
      label: "Bad actors:",
      content: actors,
    },
    watchlistSuspectArticles: {
      label: "Suspect articles:",
      content: articles,
    },
    watchlistManuscriptsOfferedForSale: {
      label: "Manuscripts offered for sale:",
      content: manuscripts,
    },
    watchlistMetadataSuspects: {
      label: "Metadata suspects:",
      content: metadata,
    },
    watchlistChatGPTOutput: {
      label: "GenAI response:",
      content: gptOutput,
    },
  };

  return (
    <MDBCard className={`mt-4 bg-opacity-25 ${getCardColorClass(suspects)}`}>
      <div className="card-header p-3 pt-4 pb-3 border-0">
        <div className="d-flex justify-content-between">
          <h5 className="fw-normal">Watchlist</h5>
        </div>
      </div>
      <div className="card-body bg-white bg-opacity-25 p-3">
        {Object.entries(metadataEntries).map(([key, entry]) =>
          renderMetadataEntry(key, entry, components[key])
        )}
      </div>
    </MDBCard>
  );
};

export default WatchlistTab;
