import React, { useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const CustomPopover = ({ popoverHeader, popoverBody, placement = 'right', children }) => {
    const ref = useRef(null);

    return (
        <div ref={ref}>
            <OverlayTrigger
                placement={placement}
                container={ref}
                delay={{ show: 50, hide: 50 }}
                overlay={(
                    <Popover
                        id="popover-basic"
                        style={{ color: 'black' }}
                    >
                        {popoverHeader && <Popover.Header as="h3">{popoverHeader}</Popover.Header>}
                        {popoverBody && <Popover.Body>{popoverBody}</Popover.Body>}
                    </Popover>
                )}
            >
                {children}
            </OverlayTrigger>
        </div>
    );
};

export default CustomPopover;
