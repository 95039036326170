import React from "react";
import QRCode from "react-qr-code";


const QR = ({ value }) => {

    return (
        <div className="d-flex justify-content-center my-5">
            <QRCode value={value} size={140}/>
        </div>
    );
};


export default QR;

