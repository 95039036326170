import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './App.css'
import App from './App';

// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';

// const theme = createMuiTheme({
//   palette: {
//     primary: purple,
//     secondary: green,
//   },
//   status: {
//     danger: 'orange',
//   },
// });

// Define what props.theme will look like
// const theme = {
//   main: "mediumseagreen"
// };

ReactDOM.render(<App />, document.getElementById('root'));