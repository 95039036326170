import { axiosInstance } from "./axiosSetup";
import { showErrorToast } from './helpers';

export const authorize = async (credentials) =>
  axiosInstance.post('authorize', { ...credentials, returnUserComponents: true });

export const MFASignIn = async (stage, payload) => axiosInstance.post(stage, { ...payload, returnUserComponents: true });

export const redirectToPermittedRoute = (props) => {
  if (props.isFeatureEnabled('PAPERMILL_CHECK')) {
    props.navigate('/papermill-check')
  } else if (props.isFeatureEnabled('WATCHLIST')) {
    props.navigate('/watchlist')
  } else {
    showErrorToast('No permitted services')
    props.navigate('/login')
  }
};
