import { toast } from 'react-toastify';
import {
    notificationsTime,
    errorMessage as defaultErrorMessage,
    successMessage as defaultSuccessMessage
} from '../constants';

export const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const checkIsAdmin = (groups) => groups.some(group => group.GroupName === 'Administrators');

export const convertComponentNames = (oldComponents) => {
    const componentsConversionMap = {
        'PMChk': 'doiResolutions',
        'TPHrs': 'torturedPhrases',
        'FOCRat': 'feetOfClayDetector',
        'CSStat': 'clearSkiesStatus',
        'RWtch': 'retractionWatch',
        'MLDet': 'unnaturalTextDetector',
        'SSPfd': 'watchlistFakeEmailDomains',
        'SSPan': 'watchlistFakeAffiliationNames',
        'SSPidd': 'watchlistFakeNamesOrEmailAddresses',
        'SSPdd': 'watchlistDisposableEmailDomains',
        'SSPba': 'watchlistBadActors',
        'SSPsa': 'watchlistSuspectArticles',
        'SSPmfs': 'watchlistManuscriptsOfferedForSale',
        'SSMeta': 'watchlistMetadataSuspects'
    };
    const newComponents = {};

    Object.entries(oldComponents).forEach(([oldName, value]) => {
        newComponents[componentsConversionMap[oldName] || oldName] = value;
    });

    return newComponents;
}

export const truncateDecimals = function (number, digits) {
    var multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    return truncatedNum / multiplier;
};

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const convertToNumber = value => isNaN(value) ? parseFloat(value) : value;

export const convertToScore = value => Math.round(value * 100);

export const isInRange = (num, num1, num2) => Math.min(num1, num2) <= num && Math.max(num1, num2) >= num;

export const isObject = (variable) => variable !== null && typeof variable === 'object' && !Array.isArray(variable);

export const isEmptyObject = o => o && Object.keys(o).length === 0 && o.constructor === Object;

export const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});

export const showSuccessToast = (successMessage = defaultSuccessMessage) => {
    return toast.success(successMessage, {
        position: "top-right",
        autoClose: notificationsTime,
    });
};

export const showErrorToast = (error) => {
    let errorMessage = error || defaultErrorMessage;

    if (error instanceof Object) {
        const responseData = error?.response?.data;
        errorMessage = responseData?.errorMessage?.message || responseData?.errorMessage || error.message || defaultErrorMessage;
    }

    return toast.error(errorMessage, {
        position: "top-right",
        autoClose: notificationsTime,
    });
};

export const showInfoToast = (message, timeOut = false) => {
    return toast.info(message, {
        position: "top-right",
        autoClose: timeOut,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: false,
    });
};

export const clearToasts = () => {
    return toast.dismiss();
};

export const countProgressValue = score => {
    if (isInRange(score, 0, 25))
        return 25
    else if (isInRange(score, 25, 50))
        return 50
    else if (isInRange(score, 50, 75))
        return 75
    else if (isInRange(score, 75, 100))
        return 100
    else
        return 0
};

export const countProgressColor = value => {
    switch (value) {
        case 25:
            return "success"
        case 50:
            return "danger"
        case 75:
            return "danger"
        case 100:
            return "danger"
        default:
            return "white"
    };
};

export const markToHref = signals => {
    let result = signals.map(signal => {
        let sentence = signal.sentence.map(element => {
            element = element.replace('<mark>', `<a href="https://pubpeer.com/search?q=${encodeURIComponent(signal.pattern)}" target="_blank">`);
            element = element.replace('</mark>', '<i className="ps-1 fa fa-external-link-alt fa-sm"></i></a>');
            return element;
        });
        return { ...signal, sentence }
    });
    return result;
};

export const filterObj = (obj, filterKey) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => key !== filterKey)
    );
};
