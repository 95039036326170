import { createContext, useContext, useState } from "react";

const AppContext = createContext({});

export const useAppContext = () => useContext(AppContext);

export const ContextWrapper = ({ children }) => {
    const [search, setSearch] = useState('');

    return (
        <AppContext.Provider value={{ search, setSearch }}>
            {children}
        </AppContext.Provider>
    )
}

export default ContextWrapper;