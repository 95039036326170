import React from "react";
import CustomPopover from "./CustomPopover";

const ServiceProcessingPopover = ({ popoverBody }) => {
  return (
    <CustomPopover popoverHeader="Service processing" popoverBody={popoverBody}>
      <span>
        <i
          className="fa fa-cog fa-spin ms-1 text-primary"
          role="img"
          aria-label="Processing"
        ></i>
      </span>
    </CustomPopover>
  );
};

export default ServiceProcessingPopover;
