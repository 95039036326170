export const screenTabColumns = [
  {
    label: 'Type',
    field: 'type',
    width: 150,
  },
  {
    label: 'Hit',
    field: 'hit',
    width: 250,
  },
  {
    label: 'Provided by',
    field: 'providedby',
    width: 200,
  },
  {
    label: 'Timestamp',
    field: 'timestamp',
    width: 200,
  },
  {
    label: 'Comment',
    field: 'comment',
  },
];

export const adminTabColumns = [
  {
    label: 'Value',
    field: 'value',
    width: 250,
  },
  {
    label: 'Timestamp',
    field: 'timestamp',
    width: 200,
  },
  {
    label: 'Comment',
    field: 'comment',
  },
];
