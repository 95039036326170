import { axiosInstance } from "./axiosSetup";

export const getWatchlist = (type, startrow, maxrows) => {
  return axiosInstance.get(
    `/watchlist?type=${type}&startrow=${startrow}&maxrows=${maxrows}`
  );
};

export const exportWatchlist = async (type) => {
  return axiosInstance.get(`/watchlist/export?type=${type}`);
};

export const importFilePresign = async (file) => {
  return axiosInstance.get(`/watchlist/import?stage=presign`, file);
};

export const importFilePreview = async (fileName, type) => {
  return axiosInstance.get(
    `/watchlist/import?stage=test&file=${fileName}&type=${type}`
  );
};

export const importFileConfirm = async (fileName, type) => {
  return axiosInstance.get(
    `/watchlist/import?stage=confirm&file=${fileName}&type=${type}`
  );
};

export const getCheckerList = async (startrow, maxrows, search) => {
  return axiosInstance.get(
    `/watchlist/lookup?startrow=${startrow}&maxrows=${maxrows}&query=${search}`
  );
};

export const duplicateWatchlistRecord = async (id) => {
  return axiosInstance.post(`/watchlist/duplicate/${id}`);
};
