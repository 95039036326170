import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { withTheme } from "styled-components";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBContainer,
  MDBNavbarLink,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { useAuth } from "./Auth";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleProfileNavigation = (event) => {
    event.preventDefault();
    navigate("/profile");
  };

  const { logout, isFeatureEnabled } = useAuth();

  const toggleCollapse = () => {
    setShow((open) => !open);
  };

  const navbarItems = [];

  if (isFeatureEnabled("PAPERMILL_CHECK")) {
    navbarItems.push({
      label: "On-Demand Screening",
      path: "/papermill-check",
    });
  }

  if (isFeatureEnabled("WATCHLIST")) {
    navbarItems.push({
      label: "Watchlist",
      path: "/watchlist",
    });
  }

  const username = localStorage.getItem("username");

  return (
    <>
      <MDBNavbar expand="lg" dark bgColor="primary">
        <MDBContainer fluid>
          <MDBNavbarToggler onClick={toggleCollapse}>
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={show}>
            <MDBNavbarNav>
              {navbarItems.map((item) => (
                <MDBNavbarItem key={item.label}>
                  <MDBNavbarLink
                    active={item.path === location.pathname}
                    className="nav-link px-3"
                    onClick={() => navigate(item.path)}
                  >
                    {item.label}
                  </MDBNavbarLink>
                </MDBNavbarItem>
              ))}
            </MDBNavbarNav>
          </MDBCollapse>
          <MDBDropdown>
            <MDBDropdownToggle className="btn btn-primary">
              <MDBIcon icon="user" className="me-2" />
              {username}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link onClick={handleProfileNavigation}>
                <MDBIcon icon="cog" className="me-2" /> Edit Profile
              </MDBDropdownItem>
              <MDBDropdownItem link onClick={logout}>
                <MDBIcon icon="sign-out-alt" className="me-2" /> Logout
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export default withTheme(Navbar);
