import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTabsContent,
} from "mdb-react-ui-kit";
import WatchListTab from "./tabs/WatchListTab";
import CheckerListTab from "./tabs/CheckerListTab";
import { useAuth } from "../../components/Auth";

export const tabs = {
  watchlist: "watchlist",
  checker: "checker",
};

const WatchListPage = () => {
  const [currentTab, setCurrentTab] = useState(tabs.checker);

  const { isPermissionGranted } = useAuth();

  return (
    <MDBContainer>
      <div className="my-5">
        <MDBRow>
          <MDBCol md="12" lg="7">
            <MDBTabs className="mb-3">
              <MDBTabsItem onClick={() => setCurrentTab(tabs.checker)}>
                <MDBTabsLink
                  active={currentTab === tabs.checker}
                  icon={<i className="fas fa-clock" />}
                >
                  Screen
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem onClick={() => setCurrentTab(tabs.watchlist)}>
                <MDBTabsLink
                  hidden={!isPermissionGranted('publisherSupervisoryRole')}
                  active={currentTab === tabs.watchlist}
                  icon={<i className="fas fa-file-export" />}
                >
                  Admin
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          </MDBCol>
        </MDBRow>
        <MDBTabsContent>
          <MDBTabsPane show={currentTab === tabs.checker}>
            <CheckerListTab />
          </MDBTabsPane>
          {isPermissionGranted('publisherSupervisoryRole') && (
            <MDBTabsPane show={currentTab === tabs.watchlist}>
              <WatchListTab />
            </MDBTabsPane>
          )}
        </MDBTabsContent>
      </div>
    </MDBContainer>
  );
};

export default WatchListPage;
