import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../components/Auth";
import LoginForm from "../../components/LoginForm";
import UserDeviceForm from "../../components/UserDeviceForm";
import PasswordChangeForm from "../../components/PasswordChangeForm";

const Login = (props) => {
    const [tab, setTab] = useState("login");
    const [authResponse, setAuthResponse] = useState(null);
    const { login, logout: resetUserState, isFeatureEnabled } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        resetUserState();
    }, []);

    return (
        <>
            {
                tab === "login"
                    ? <LoginForm
                        setAuthResponse={setAuthResponse}
                        setTab={setTab}
                        login={login}
                        isFeatureEnabled={isFeatureEnabled}
                        navigate={navigate}
                        {...props} />
                    : tab === "qr"
                        ? <UserDeviceForm
                            authResponse={authResponse}
                            setAuthResponse={setAuthResponse}
                            setTab={setTab}
                            login={login}
                            isFeatureEnabled={isFeatureEnabled}
                            navigate={navigate}
                            {...props} />
                        : tab === "change"
                            ? <PasswordChangeForm
                                authResponse={authResponse}
                                setTab={setTab}
                                login={login}
                                logout={resetUserState}
                                {...props} />
                            : null
            }
        </>
    );
};

export default Login;