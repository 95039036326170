import React, { useEffect, useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import Pagination from "rc-pagination";
import { useNavigate } from "react-router-dom";
import { itemsPerPage } from "../../../constants";
import { getWatchlist } from "../../../helpers/watchlist";
import { adminTabColumns } from "../columns";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import SuspectsTypeSelect from "../components/SuspectsTypeSelect";
import DataTable from "../../../components/DataTable/DataTable";
import Spinner from "../../../components/Spinner/Spinner";
import { ExportModal } from "../components/ExportModal";

const WatchListTab = () => {
  const navigate = useNavigate();

  const [suspectsType, setSuspectsType] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [watchlistData, setWatchlistData] = useState(null);

  const handlePageClick = (nextPage) => {
    setCurrentPage(nextPage);
    const newStartRow = (nextPage - 1) * itemsPerPage + 1;
    fetchWatchlist(suspectsType, newStartRow);
  };

  const handleSuspectTypeChange = (event) => {
    const newSuspectType = event.value;
    setSuspectsType(newSuspectType);
    setCurrentPage(1);
    fetchWatchlist(newSuspectType, 1);
  };

  const fetchWatchlist = async (suspectType, startrow) => {
    setLoading(true);
    try {
      const res = await getWatchlist(suspectType, startrow, itemsPerPage);
      const { suspects: data, total } = res.data;
      setWatchlistData(data);
      setTotal(total);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWatchlist(suspectsType, 1);
  }, []);

  const handleImportNavigate = () => {
    navigate('/watchlist-import', {
      state: { suspectsType: suspectsType },
    });
  };
  return (
    <div>
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        suspectsType={suspectsType}
      />
      <MDBRow>
        <MDBCol className="text-end">
          <MDBBtn
            className="mb-4 me-3"
            color="secondary"
            size="md"
            onClick={handleImportNavigate}
            disabled={loading}
          >
            <MDBIcon icon="fas fa-file-import" className="me-2" /> Import
          </MDBBtn>
          <MDBBtn
            className="mb-4"
            color="secondary"
            size="md"
            onClick={() => setIsExportModalOpen(true)}
          >
            <MDBIcon icon="fas fa-file-export" className="me-2" /> Export
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <div className="table_header table-primary d-flex px-3 py-2">
        <MDBCol sm={8} md={6} lg={4}>
          <SuspectsTypeSelect
            suspectType={suspectsType}
            onSuspectTypeChange={handleSuspectTypeChange}
          />
        </MDBCol>
      </div>
      {loading && <Spinner />}
      {!loading && watchlistData?.length > 0 && (
        <>
          <DataTable columns={adminTabColumns} rows={watchlistData} />
          <MDBRow className="text-end">
            <Pagination
              simple
              total={total}
              pageSize={itemsPerPage}
              current={currentPage}
              onChange={handlePageClick}
              locale={"en_US"}
            />
          </MDBRow>
        </>
      )}
      {!loading && error && <ErrorMessage />}
      {!loading && watchlistData?.length === 0 && (
        <NoContentMessage message={"No data"} />
      )}
    </div>
  );
};

export default WatchListTab;
