import React from "react";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import {
  updatePassword,
  showSuccessToast,
  showErrorToast,
  getComponents,
} from "../../helpers";
import { regExpPassword } from "../../constants";
import Spinner from "../../components/Spinner/Spinner";

const ERROR = "Error, something went wrong";

const PasswordUpdateForm = ({ onSubmitCallback }) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.password) {
        errors.password = "'New Password' is required";
      } else if (!values.password.match(regExpPassword)) {
        errors.password =
          "Minimum eight characters, at least one uppercase and one lowercase letter, one number and one special character";
      }
      if (!values.passwordConfirm) {
        errors.passwordConfirm = "'Confirm New Password' is required";
      } else if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = "Passwords do not match";
      }
      return errors;
    },
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    try {
      const { data } = await getComponents();
      const userId = data?.userId;
      await updatePassword(userId, { password: values.password, passwordSetupType: 3 });
      showSuccessToast("Updated successfully");
      formik.resetForm();
      onSubmitCallback && onSubmitCallback();
    } catch (error) {
      console.error(error);
      showErrorToast(ERROR);
    }
  };

  return (
    <div className="p-3">
      {formik.isSubmitting && <Spinner />}
      {!formik.isSubmitting && (
        <form onSubmit={formik.handleSubmit}>
          <label className="form-label mt-3">New Password</label>
          <MDBInput
            name="password"
            type="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-danger">{formik.errors.password}</div>
          ) : null}
          <label className="form-label mt-3">Confirm New Password</label>
          <MDBInput
            name="passwordConfirm"
            type="password"
            {...formik.getFieldProps("passwordConfirm")}
          />
          {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
            <div className="text-danger">{formik.errors.passwordConfirm}</div>
          ) : null}
          <div className="text-center mt-4">
            <MDBBtn color="primary" type="submit">
              Change Password
            </MDBBtn>
          </div>
        </form>
      )}
    </div>
  );
};

export default PasswordUpdateForm;
