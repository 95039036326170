import React from 'react';
import CustomPopover from './CustomPopover';
import { SERVICE_ERROR_TEXT } from '../../constants';

const ServiceErrorPopover = () => (
    <CustomPopover popoverHeader="Service unavailable" popoverBody={SERVICE_ERROR_TEXT}>
        <span>
            <i
                style={{ color: 'orange' }}
                className="fa fa-exclamation-triangle ms-1"
                role="img"
                aria-label="Warning"
            ></i>
        </span>
    </CustomPopover>
);

export default ServiceErrorPopover;