import React from "react";
import { isNumeric } from "../../helpers/helpers";

const RefLink = ({
  item,
  index,
  pubpeer = false,
  comments = null,
  textColor = null,
}) => {
  return (
    <div style={{ padding: "1px" }}>
      <span>{index + 1}. </span>
      <a
        href={
          pubpeer
            ? `https://pubpeer.com/search?q=${encodeURIComponent(item)}`
            : `https://doi.org/${item}`
        }
        target="_blank"
        rel="noreferrer"
      >
        {item}
        <i className="ps-1 pe-2 fa fa-external-link-alt fa-sm"></i>
      </a>
      {
        <>
          {isNumeric(comments) && (
            <span
              style={{
                display: "inline-block",
                minWidth: "22px",
                textAlign: "center",
                backgroundColor: "#007bff",
                color: "white",
                borderRadius: "4px",
                marginLeft: "10px",
                padding: "1px",
              }}
            >
              {comments}
            </span>
          )}
        </>
      }
    </div>
  );
};

export default RefLink;
