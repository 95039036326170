import React, { useCallback, useEffect, useRef, useState } from "react";
import { MDBBtn, MDBCol, MDBInputGroup, MDBRow } from "mdb-react-ui-kit";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import Spinner from "../../../components/Spinner/Spinner";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import DataTable from "../../../components/DataTable/DataTable";
import { getCheckerList } from "../../../helpers/watchlist";
import { itemsPerPage } from "../../../constants";
import { screenTabColumns } from "../columns";
import Pagination from "rc-pagination";
import DetailedViewModal from '../components/DetailedViewModal';

const CheckerListTab = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [checkerData, setCheckerData] = useState(null);
  const [searchState, setSearchState] = useState("");

  const [detailedViewItem, setDetailedViewItem] = useState(null);

  const isInitialRender = useRef(true);

  const handlePageClick = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const fetchCheckerList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCheckerList(
        (currentPage - 1) * itemsPerPage + 1,
        itemsPerPage,
        searchState
      );
      const { suspects: data, total } = response.data;

      setCheckerData(data);
      setTotal(total);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchState]);

  const handleSearch = async () => {
    setCurrentPage(1);
    await fetchCheckerList(1);
    isInitialRender.current = false;
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchState.length >= 3) {
      handleSearch();
    }
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      fetchCheckerList();
    }
  }, [currentPage]);

  const checkerDataWithActions =
    checkerData?.map((item) => {
      return {
        ...item,
        hit: item.value,
        clickEvent: () => setDetailedViewItem(item),
      };
    }) ?? [];

  return (
    <>
      <DetailedViewModal
        item={detailedViewItem}
        onClose={() => setDetailedViewItem(null)}
      />
      <div className="table_header table-primary d-flex px-3 py-2">
        <MDBCol sm={8} md={6} lg={4}>
          <MDBInputGroup className="p-1">
            <input
              className="form-control"
              placeholder="Minimum three letters to search..."
              type="text"
              onChange={(e) => setSearchState(e.target.value)}
              value={searchState}
              onKeyDown={handleKeyPress}
            />
            <MDBBtn disabled={searchState?.length < 3} onClick={handleSearch}>
              Search
            </MDBBtn>
          </MDBInputGroup>
        </MDBCol>
      </div>
      {loading && <Spinner />}
      {!loading && checkerData?.length > 0 && (
        <>
          <DataTable columns={screenTabColumns} rows={checkerDataWithActions} />
          <MDBRow className="text-end">
            <Pagination
              simple
              total={total}
              pageSize={itemsPerPage}
              current={currentPage}
              onChange={handlePageClick}
              locale={"en_US"}
            />
          </MDBRow>
        </>
      )}
      {checkerData?.length === 0 && <NoContentMessage message={"No data"} />}
      {error && <ErrorMessage />}
    </>
  );
};

export default CheckerListTab;
