import React, { useEffect } from "react";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { withFormik } from "formik";
import Loader from "./Loader";
import QR from "./QR";
import { MFASignIn, redirectToPermittedRoute } from "../helpers/auth";
import {
  filterObj,
  showSuccessToast,
  showErrorToast,
} from "../helpers/helpers";
import { notificationsTime } from "../constants";
import "./LoginForm.css";

const UserDeviceForm = ({
  authResponse,
  handleSubmit,
  isSubmitting,
  setSubmitting,
  setValues,
  setFieldValue,
  status,
  setTab,
  login,
  ...props
}) => {
  useEffect(() => {
    if (status?.deviceLinked) {
      showSuccessToast(
        "You have successfully linked your TOTP device to your account. Please login with your new MFA flow"
      );
      setTimeout(() => {
        setTab("login");
      }, notificationsTime);
    }
    if (status?.wrongMfaCode) {
      setTab("login");
    }
    if (status?.authorized) {
      if (authResponse?.status === "FORCE_CHANGE_PASSWORD") {
        setTab("change");
      } else {
        login(authResponse);
        redirectToPermittedRoute(props);
      }
    }
  }, [status]);

  useEffect(() => {
    if (authResponse) {
      setValues({
        usercode: "",
        session: authResponse.session,
        username: authResponse.username,
        mfa: authResponse.mfa,
        AccessToken: authResponse.AccessToken,
      });
    }
  }, [authResponse, setValues]);

  const btnValue = authResponse?.mfa ? "Login" : "Link";
  const handleCodeChange = (e) => setFieldValue("usercode", e.target.value);

  return (
    <div className="main-section">
      <div className="form_wrapper d-flex justify-content-center">
        {isSubmitting ? (
          <Loader />
        ) : (
          <form className="col-10 col-md-6" onSubmit={handleSubmit}>
            {!authResponse.mfa ? (
              <>
                <div className="font-weight-bolder mb-3">
                  <p>
                    Add your TOTP authenticator to your account. You can either
                    add a code manually or scan a QR-code to link your MFA
                    device.
                  </p>
                  <p>Enter the resulting 6-figures code and click 'LINK'</p>
                </div>
                <QR value={authResponse.qr} />
                <p className="font-weight-bolder my-3 user_code">
                  Your code: {authResponse.code}
                </p>
              </>
            ) : null}
            <MDBInput
              onChange={handleCodeChange}
              name="usercode"
              label="Code"
              type="text"
              autoFocus
            />
            <div className="text-center mt-4">
              <MDBBtn color="primary" type="submit">
                {btnValue}
              </MDBBtn>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

const mapPropsToValues = (props) => ({
  usercode: "",
  session: props.session,
  username: props.username,
  mfa: props.mfa,
  AccessToken: props.AccessToken,
});

const handleSubmit = async (values, { props, setSubmitting, setStatus }) => {
  setSubmitting(true);

  const payload = {
    ...filterObj(values, "mfa"),
  };

  try {
    const response = await MFASignIn(
      values.mfa ? "mfaverify" : "mfasetup",
      payload
    );

    if (values.mfa) {
      props.setAuthResponse((prev) => ({ ...prev, ...response.data }));
      setStatus({ authorized: true });
    } else {
      setStatus({ deviceLinked: true });
    }
  } catch (error) {
    showErrorToast(error);
    if (values.mfa && error) {
      setStatus({ wrongMfaCode: true, error: error });
    }
  } finally {
    setSubmitting(false);
  }
};
export default withFormik({ handleSubmit, mapPropsToValues })(UserDeviceForm);
