import React from "react";
import Layout from "./Layout";
import Footer from "./components/Footer";
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <ThemeProvider theme={{}}>
        <ToastContainer />
        <Layout />
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default App;