import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <MDBFooter color='white' bgColor='primary' className='footer d-flex justify-content-start align-items-center font-small'>
            <div className='p-3'>&#169; Copyright 2022 - {currentYear} STM Solutions</div>
        </MDBFooter>
    );
};

export default Footer;
