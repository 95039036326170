import React, { useEffect } from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import { withFormik, Field } from 'formik';
import TextInput from './TextInput';
import Loader from './Loader';
import { authorize, redirectToPermittedRoute } from '../helpers/auth';
import { showErrorToast, checkIsAdmin } from '../helpers/helpers';
import './LoginForm.css';

const mapPropsToValues = () => ({
    username: '',
    password: '',
});

const authorizeAction = (values, actions) => {
    const username = values.username.trim().toLowerCase();
    authorize({ ...values, username })
        .then(response => {
            actions.setStatus({
                username,
                mfa: response.data.nextstep === 'SOFTWARE_TOKEN_MFA' ? true : false,
                forceChangePassword: response.data?.status === 'FORCE_CHANGE_PASSWORD',
                currentPassword: values.password,
                ...response.data
            })
        })
        .catch(error => {
            showErrorToast(error);
        })
        .finally(() => {
            actions.setSubmitting(false);
        });
}

const handleSubmit = (values, { props, ...actions }) => {
    authorizeAction(values, actions);
};

const LoginForm = ({
    setAuthResponse,
    status,
    setStatus,
    setTab,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    values,
    ...props
}) => {
    useEffect(() => {
        if (status) {
            setAuthResponse(prevStatus => ({ ...prevStatus, ...status }));
            if (status.mfa || status.nextstep === 'MFA_SETUP') {
                setTab('qr');
            } else {
                if (status.forceChangePassword) {
                    setTab('change');
                } else {
                    props.login(status);
                    redirectToPermittedRoute(props);
                }
            }
        }
    }, [status]);

    return (
        <div className='main-section'>
            <div className='form_wrapper d-flex justify-content-center'>
                {isSubmitting
                    ? <Loader />
                    : <form className='col-10 col-md-4' onSubmit={handleSubmit}>
                        <h4 className='text-center mb-3 font-weight-bolder'>Log in</h4>
                        <Field component={TextInput} name='username' label='Username' type='text' />
                        <Field component={TextInput} name='password' label='Password' type='password' />
                        <div className='text-center mt-4'>
                            <MDBBtn color='primary' type='submit'>Login</MDBBtn>
                        </div>
                    </form>
                }
            </div>
        </div >
    );
};

export default withFormik({ handleSubmit, mapPropsToValues })(LoginForm);
