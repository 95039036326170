import React, { useState } from 'react';
import {
    MDBContainer, MDBRow, MDBCol,
    MDBTabs, MDBTabsItem, MDBTabsLink,
    MDBTabsContent, MDBTabsPane, MDBCard,
    MDBCardBody, MDBCardTitle, MDBCardText,
    MDBCardFooter
} from 'mdb-react-ui-kit';
import PasswordChangeForm from './PasswordChangeForm'

export default function ProfilePage() {
    const [activeTab, setActiveTab] = useState('profile');
    const username = localStorage.getItem('username');

    const handleTabClick = (value) => {
        if (value === activeTab) {
            return;
        }
        setActiveTab(value);
    };

    return (
        <MDBContainer className="mt-5">
            <MDBRow className="justify-content-center">
                <MDBCol md="9" lg="7" xl="5">
                    <MDBTabs className='mb-3 mt-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTabClick('profile')} active={activeTab === 'profile'}>
                                Profile
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTabClick('password')} active={activeTab === 'password'}>
                                Change Password
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>
                    <MDBTabsContent>
                        <MDBTabsPane show={activeTab === 'profile'}>
                            <div className="pt-3 ">
                                <MDBCard style={{}}>
                                    <MDBCardBody>
                                        <MDBCardTitle className="text-center mb-4">Profile Information</MDBCardTitle>
                                        <MDBCardText>
                                            <strong>Username: </strong>{username}
                                        </MDBCardText>
                                    </MDBCardBody>
                                    <MDBCardFooter />
                                </MDBCard>
                            </div>
                        </MDBTabsPane>
                        <MDBTabsPane show={activeTab === 'password'}>
                            <PasswordChangeForm onSubmitCallback={() => setActiveTab('profile')} />
                        </MDBTabsPane>
                    </MDBTabsContent>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};