const ReferenceList = ({
  title,
  references,
  isDataAvailable,
  renderFunction,
  isLastElement,
}) => (
  <div className={`mt-1 ${isLastElement ? "mb-1" : "mb-3"}`}>
    <p className="fw-bold mb-1">{title}:</p>
    {references?.length
      ? references.map(renderFunction)
      : isDataAvailable
      ? "None found"
      : "N/A"}
  </div>
);

export default ReferenceList;
